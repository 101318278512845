.game-tables-team-table-header {
	
	height: 25px;
	color: #FFF;
	font-size: 10px;
	line-height: 25px;
	background: #1D4288;
	
	@media screen and (min-width: 992px) {
	height: 40px;
	font-size: 12px;
	line-height: 40px;
	}
	
	.table-row-cell {
		background: #1D4288;
	}
	
}
