.leagues-schedules {
	
	&__league-rounds-list {
		
		&__league_round {
			
			padding: 0 15px;
			border-bottom: 1px solid #17366E;
			
			@media screen and (min-width: 992px) {
			padding: 50px 0;
			}
			
			&:last-child {
			border-bottom: none;
			}
			
		}
		
	}
	
}
