.team-page {
	
	&__team-players {
		
		&__team-games {
			
			margin: 10px 0;
			padding-top: 10px;
			border-top: 1px solid #D8D9D9;
			
			@media screen and (min-width: 992px) {
			padding-top: 0;
			padding-left: 45px;
			border-top: none;
			}
			
			.system-message-component {
				margin-top: 0;
			}
			
			&__content {
				margin: 0 -10px;
			}
			
			&__header {
				
				font-size: 12px;
				padding-bottom: 5px;
				text-transform: uppercase;
				font-weight: 600;
				text-align: center;
				border-bottom: 1px solid #D8D9D9;
				
				@media screen and (min-width: 992px) {
				margin-top: 10px;
				font-size: 16px;
				margin-bottom: 15px;
				border-bottom: none;
				}
				
			}
			
			&__list {
				
				position: relative;
				text-align: center;
				
				.game-order-nr,
				.game-day, .game-hour,
				.game-arena {
					padding: 0;
				}
				
				.game-order-nr {
					flex: 0 0 8%;
					max-width: 8%;
				}
				
				.game-day, .game-hour {
					flex: 0 0 11%;
					max-width: 11%;
				}
				
				.game-arena {
					flex: 0 0 35%;
					max-width: 35%;
				}
				
				.game-opponent-team {
					flex: 0 0 35%;
					max-width: 35%;
				}
				
				&__header {
					
					padding: 5px 15px;
					text-transform: uppercase;
					background: #E6E6E6;
					border-bottom: 2px solid #17366E;
					
					.game-order-nr,
					.game-day, .game-hour,
					.game-arena {
						border-right: 2px solid #E6E6E6;
					}
					
				}
				
				&__game {
					
					background: #FFF;
					
					&:nth-child(odd) {
						background: #E6E6E6;
					}
					
					//&__content {
					//	margin: 0;
					//}
					
					.game-order-nr,
					.game-day, .game-hour,
					.game-arena, .game-opponent-team {
						display: flex;
						padding: 5px 5px;
						line-height: 20px;
						@media screen and (min-width: 992px) {
						min-height: 50px;
						justify-content: center;
						align-items: center;
						}
					}
					
					.game-order-nr,
					.game-day, .game-hour,
					.game-arena {
						border-right: 2px solid #D8D9D9;
					}
					
					.game-opponent-team {
						font-weight: 600;
					}
					
				}
				
			}
		
		}
		
	}
	
}
