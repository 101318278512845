.team-page {
	
	&__team-players {
		
		&__players-list {
			
			margin-top: 20px;
			padding-top: 20px;
			border-top: 2px solid #17366E;
			
			@media screen and (min-width: 992px) {
			margin-top: 30px;
			padding-top: 30px;
			}
			
			.players-list-header {
				
				margin-bottom: 20px;
				font-size: 18px;
				font-weight: 600;
				text-transform: uppercase;
				
				@media screen and (min-width: 992px) {
				margin-bottom: 30px;
				font-size: 26px;
				}
				
			}
			
			.team-player {
				
				position: relative;
				margin-bottom: 15px;
				@media screen and (min-width: 992px) {
				margin-bottom: 30px;
				}
				
				.team-player-content {
					margin: 0;
				}
				
				.player-avatar {
					
					display: flex;
					justify-content: center;
					align-items: center;
					align-content: center;
					margin-bottom: 5px;
					padding: 10px;
					height: 100px;
					background: #FFF;
					box-shadow: 5px 5px 5px #D8D9D9;
					
					@media screen and (min-width: 992px) {
					margin-bottom: 10px;
					padding: 15px;
					height: 230px;
					}
					
					a {
						display: block;
						
						&:hover {
							opacity: 0.7;
						}
					}
					
					img {
						max-height: 80px;
						@media screen and (min-width: 992px) {
						max-height: 200px;
						}
					}
					
				}
				
				.player-name {
					
					font-weight: 600;
					text-align: center;
					text-transform: uppercase;
					
					@media screen and (min-width: 992px) {
					padding: 0;
					}
					
					span {
						
						&.player-captain-status {
							margin-left: 5px;
							font-size: 16px;
							color: #000;
						}
						
						&.player-number {
							display: block;
						}
						
					}
					
				}
				
			}
			
		}
		
	}
	
}
