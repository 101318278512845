
.results-bar-component {
	
	position: relative;
	
	.main-section-container {
		position: relative;
	}
	
}

.plka-advertising-banner {
	
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	align-content: center;
	overflow: hidden;
	max-height: 67px;
	
	@media screen and (min-width: 1300px) {
	display: flex;
	position: absolute;
	top: 0;
	width: auto;
	max-width: 150px;
	height: 135px;
	min-height: 135px;
	padding: 44px 0;
	align-content: center;
	align-items: center;
	}
	
	&.left-banner {
		margin-top: 5px;
		margin-bottom: 20px;
		@media screen and (min-width: 1300px) {
		margin-top: 0;
		margin-bottom: 0;
		left: -200px;
		}
	}
	
	&.right-banner {
		margin-top: 15px;
		margin-bottom: 5px;
		@media screen and (min-width: 1300px) {
		margin-top: 0;
		margin-bottom: 0;
		right: -200px;
		}
	}
	
	a {
		display: block;
		&:hover {
			opacity: 0.7;
		}
	}
	
	img {
		max-height: 47px;
	}
	
}


.games-results-bar {
	
	position: relative;
	
	@media screen and (min-width: 992px){
	min-height: 135px;
	}
	
	.navigation-arrow {
		
		display: flex;
		position: absolute;
		top: calc(50% - 15px);
		width: 30px;
		height: 30px;
		padding: 0;
		justify-content: center;
		align-items: center;
		z-index: 999;
		cursor: pointer;
		
		&.previous-arrow {
			left: -15px;
			@media screen and (min-width: 992px) {
			left: -25px;
			}
		}
		
		&.next-arrow {
			right: -15px;
			@media screen and (min-width: 992px) {
			right: -25px;
			}
		}
		
		svg {
			width: 20px;
			height: 20px;
			color: #17366E;
		}
		
		&:hover {
			svg {
				color: #EE7C00;
			}
		}
		
		&.disabled {
			
			cursor: default;
			
			svg {
				color: #D8D9D9;
			}
			
		}
		
	}

}


.results-bar-component {
	
	overflow: hidden;
	display: flex;
	align-content: center;
	align-items: center;
	position: relative;
	//max-height: 162px;
	padding: 15px;
	border-top: 1px solid #E5E5E5;
	border-bottom: 1px solid #E5E5E5;
	
	@media screen and (min-width: 1300px) {
	height: 137px;
	max-height: 137px;
	padding: 0 30px;
	}
	
	.slick-prev, .slick-next{
		&::before {
			color: #17366E;
		}
		&:hover {
			&::before {
				color: #EE7C00;
			}
		}
	}
	
	.games-results-component {
		
		position: relative;
		margin: 10px 0;
		@media screen and (min-width: 992px) {
		margin-top: 0;
		}
		
		.slick-slide {
			padding: 0 5px;
			@media screen and (min-width: 992px) {
			padding: 0;
			}
		}
		
		.results-bar-item-component {
			
			//height: 100%;
			display: flex;
			max-height: 140px;
			justify-content: center;
			margin-right: 10px;
			padding: 0 15px;
			background: #EBEBEB;
			border: 1px solid #17366E;
			
			@media screen and (min-width: 992px) {
			width: 229px;
			height: 135px;
			margin: 0 10px;
			}
			
			&.live-result {
				border-color: #EE7C00;
			}
			
			
			@media screen and (min-width: 1200px) {
				width: 230px;
			}
			
			.results-bar-item-content {
				
				align-content: flex-start;
				padding: 5px;
				text-transform: uppercase;
				@media screen and (min-width: 992px) {
				width: 228px
				}
				@media screen and (min-width: 1200px) {
				width: 228px;
				}
				
				.results-bar-item-header {
					
					position: relative;
					height: 22px;
					border-bottom: 2px solid #17366E;
					
					.league-game-date,
					.league-game-streams {
						height: 20px;
						font-weight: 500;
						line-height: 20px;
					}
					
					.league-game-date {
						
						padding: 0 5px;
						font-size: 9px;
						color: #FFF;
						background: #1D4288;
						
						@media screen and (min-width: 992px) {
						font-size: 11px;
						}
						
						span {
							display: inline-block;
							vertical-align: top;
							overflow: hidden;
							height: 20px;
							margin-right: 5px;
							line-height: 22px;
							&:last-child {
								margin-right: 0;
							}
						}
						
					}
					
					.league-game-streams {
						
						padding: 0 5px;
						font-size: 10px;
						text-align: center;
						background: #17366E;
						
						.league-game-streams-content {
							margin: 0;
							align-items: center;
							align-content: center;
							@media screen and (min-width: 992px) {
							margin: 0 -5px;
							}
						}
						
						.league-game-stream,
						.league-game-streams-list {
							padding: 0;
							text-align: center;
							a.not-active {
								color: #EBEBEB;
							}
						}
						
						.league-game-stream {
							
							display: none;
							padding-top: 1px;
							
							@media screen and (min-width: 992px) {
							display: block;
							}
							
							span {
								font-size: 9px;
								font-weight: 500;
								color: #FFF;
							}
							
						}
						
						.league-game-streams-list {
							padding-bottom: 2px;
							line-height: 14px;
							background: #1D4288;
							text-align: left;
							a {
								display: inline-block;
								margin: 0 3px;
								color: #FFF;
								&.youtube, &.facebook {
									margin-top: 1px;
									background: #FFF;
									svg {
										vertical-align: top;
									}
								}
								&.youtube{
									svg {
										color: #FF0000;
									}
								}
								&.facebook {
									svg {
										color: #17366E;
									}
								}
								&:hover {
									color: #EE7C00;
								}
								svg {
									width: 14px;
									height: 14px;
								}
							}
						}
						
					}
					
				}
				
				.results-bar-item-data {
					
					position: relative;
					font-size: 11px;
					line-height: 15px;
					
					@media screen and (min-width: 992px) {
					font-size: 12px;
					}
					
					.league-game-result-label {
						height: 15px;
						margin-bottom: 5px;
						padding-top: 5px;
						padding-left: 8px;
						font-weight: 600;
						text-align: center;
					}
					
					.league-game-team {
						
						.league-game-team-data,
						.league-game-team-points {}
						
						.league-game-team-data {
							
							display: flex;
							overflow: hidden;
							align-items: center;
							align-content: center;
							padding: 0 0 0 5px;
							
							.game-team-logo,
							.game-team-name {
								display: inline-flex;
								height: 30px;
								padding-top: 5px;
								align-items: center;
								align-content: center;
								a {
									display: block;
								}
							}
							
							.game-team-logo {
								min-width: 45px;
								margin-right: 5px;
								//text-align: center;
								//justify-content: center;
								img {
									max-width: 100%;
									max-height: 25px;
									//border-radius: 25px;
								}
							}
							
							.game-team-name {
								overflow: hidden;
								flex: 0 0 100%;
								font-size: 12px;
								line-height: 15px;
								span {
									display: inline-flex;
									max-height: 30px;
								}
							}
							
							&.winner {
								font-weight: 600;
							}
							
						}
						
						.league-game-team-points {
							
							display: inline-flex;
							height: 30px;
							padding-left: 20px;
							align-items: center;
							font-size: 18px;
							justify-content: center;
							
							@media screen and (min-width: 992px) {
							padding-left: 15px;
							justify-content: center;
							}
							
							&.winner {
								font-weight: 600;
							}
							
						}
						
						&.league-game-home-team {
							.league-game-team-data {
							
							}
							.league-game-team-points {
								padding-top: 5px;
							}
						}
						
						&.league-game-away-team {
							.league-game-team-data {
							
							}
							.league-game-team-points {
								padding-top: 5px;
								//padding-bottom: 5px;
							}
						}
						
					}
					
				}
				
				.results-bar-item-footer {
					
					overflow: hidden;
					margin: 5px 0;
					padding: 0 10px;
					height: 20px;
					font-size: 9px;
					font-weight: 600;
					text-align: right;
					background: #E5E5E5;
					
					@media screen and (min-width: 992px) {
					font-size: 11px;
					}
					
					span {}
					
				}
				
			}
			
			
		}
		
	}
	
}
