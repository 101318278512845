.game-result-cast {
	
	margin: 15px 0 15px;
	font-weight: 600;
	line-height: 16px;
	
	@media screen and (min-width: 992px) {
	margin: 0 0 20px;
	}
	
	.game-result-cast-content {
		justify-content: center;
	}
	
	.game-referees, .game-commissioner, .game-spectators {
		
		margin-bottom: 10px;
		text-align: center;
		
		@media screen and (min-width: 992px) {
		margin-bottom: 0;
		}
		
		
		
	}
	
	.game-referees {
		
		@media screen and (min-width: 992px) {
		text-align: right;
		padding-right: 10px;
		}
		
		&:last-child {
			padding-right: 0;
		}
		
	}
	
	.game-commissioner {
		
		@media screen and (min-width: 992px) {
		padding-left: 10px;
		text-align: left;
		border-left: 2px solid #17366E;
		}
		
		&:first-child {
			padding-left: 0;
			border-left: none;
		}
		
		&.standalone {
			padding-left: 15px;
			text-align: center;
		}
		
	}
	
	.game-spectators {
		@media screen and (min-width: 992px) {
		text-align: right;
		}
	}
	
	.info-label {
		color: #6C757D;
	}
	
	.info-value {
		margin-left: 5px;
	}
	
	.game-spectators {
		.info-label {
			color: #1D4288;
		}
		.info-value {
			font-weight: 500;
		}
	}
	
}
