#application-header-component {
	
	//position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	padding-bottom: 10px;
	background: #17366E;
	border-top: 1px solid #17366E;
	border-bottom: 5px solid #1D4288;
	z-index: 9999;
	
	@media screen and (min-width: 992px) {
	position: relative;
	height: 120px;
	border-bottom: 10px solid #17366E;
	}
	
	.main-section-content {
	position: relative;
	}
	
	.header-logo-component,
	.header-plka-logo-component,
	.header-navigation-component,
	.header-socials-component {}
	
}
