.team-page {
	
	&__team-header {
		
		&__team-league {
			
			margin-top: 15px;
			padding: 0;
			
			@media screen and (min-width: 992px) {
			margin-top: 0;
			padding: 0 15px;
			}
			
			.team-league-content {}
			
			.team-league-label {
				font-size: 16px;
				color: #FFF;
				text-transform: uppercase;
				@media screen and (min-width: 992px) {}
			}
			
			.team-league-name {
				
				@media screen and (min-width: 992px) {
				margin-top: 10px;
				}
				
				a {
					
					display: block;
					font-size: 16px;
					color: #FFF;
					
					&:hover {
						color: #EE7C00;
					}
					
				}
				
			}
			
		}
		
	}
	
}

