.header-navigation-component {
	
	position: relative;
	height: 60px;
	padding: 0;
	z-index: 9999;
	
	@media screen and (min-width: 992px) {
	display: flex;
	align-items: center;
	align-content: center;
	height: 100px;
	margin: -20px 0;
	padding: 30px 0;
	background: #1D4288;
	}
	
	.main-navigation-display-switcher {
		
		position: absolute;
		top: 15px;
		left: 0;
		width: 25px;
		height: 25px;
		color: #FFF;
		cursor: pointer;
		
		@media screen and (min-width: 992px) {
		display: none;
		}
		
		svg {
			width: 25px;
			height: 25px;
		}
		
		&.opened {
			color: #EE7C00;
		}
		
	}
	
	.navigation-links-list {
		
		display: none;
		list-style: none;
		margin: 0;
		padding: 0;
		justify-content: space-between;
		
		@media screen and (min-width: 992px) {
		display: block;
		text-align: center;
		}
		
		.main-navigation-close-handler {
			position: absolute;
			top: 10px;
			right: 15px;
			@media screen and (min-width: 992px) {
				display: none!important;
			}
			svg {
				width: 20px;
				height: 20px;
				color: #EE7C00;
			}
		}
		
		&.opened {
			display: block;
			@media screen and (max-width: 991px) {
			position: absolute;
			top: 95px;
			right: 0;
			min-width: 320px;
			padding: 15px 50px 15px 15px;
			background: #FFF;
			z-index: 9999;
			}
		}
		
		
		.navigation-list-item {
			
			display: block;
			position: relative;
			padding: 0 15px 10px;
			margin-bottom: 10px;
			@media screen and (min-width: 992px) {
				display: inline-block;
				width: auto;
				margin-bottom: 0;
				padding: 0 5px;
				border-bottom: 1px solid #1D4288;
				&:last-child {
					margin-bottom: 0;
					padding-bottom: 0;
					border-bottom: none;
				}
			}
			
			.navigation-list-link {
				
				display: block;
				font-size: 12px;
				font-weight: 500;
				text-align: right;
				text-transform: uppercase;
				line-height: 20px;
				@media screen and (max-width: 991px) {
					font-weight: 600;
					text-align: left;
				}
				@media screen and (min-width: 992px) {
					color: #FFF;
					text-align: center;
				}
				
				ul {
					display: none;
				}
				
				&:hover, &.active {
					color: #EE7C00;
				}
				
			}
			
			
			.navigation-teams-menu {
				
				list-style: none;
				margin: 5px 0 0;
				padding: 0 15px;
				@media screen and (min-width: 992px) {
					position: absolute;
					top: 70px;
					left: 15px;
					min-width: 220px;
					margin: 0;
					padding: 0;
				}
				
				li {
					
					margin-bottom: 5px;
					padding: 0;
					@media screen and (min-width: 992px) {
						margin-bottom: 0;
						padding: 5px 5px;
						background: #1D4289;
						border-bottom-right-radius: 20px;
						&:nth-child(2) {
							background: #2B519B;
						}
						
						&:nth-child(3) {
							background: #5374B4;
						}
						
						&:nth-child(4) {
							background: #7896D5;
						}
					}
					&:last-child {
						margin-bottom: 0;
					}
					
					a {
						display: block;
						text-transform: uppercase;
						@media screen and (min-width: 992px) {
							color: #FFF;
							&:hover {
								color: #EE7C00;
							}
						}
					}
					
					
					
				}
				
			}
			
			
		}
		
	}
	
}
