.partners-page {
	
	&__partners-list {
		
		&__partner {
			
			margin: 20px 0;
			padding: 0 15px 30px;
			border-bottom: 1px solid #CCC;
			
			@media screen and (min-width: 992px) {
			max-width: calc(33.33333% - 30px);
			flex: 0 0 calc(33.33333% - 30px);
			margin-bottom: 45px;
			margin-right: 45px;
			padding: 15px 30px;
			border-bottom: none;
			background: url("/Assets/Images/contact_bg.png") 100% 100% no-repeat;
			background-size: cover;
			}
			
			&:nth-child(3n + 3) {
				@media screen and (min-width: 992px) {
				margin-right: 0;
				}
			}
			
			&:last-child {
				padding-bottom: 0;
				border-bottom: none;
			}
			
			&__header,
			&__content,
			&__footer {
				@media screen and (min-width: 992px) {
					color: #FFF;
					a{
						color: #FFF;
						&:hover {
							color: #EE7C00;
						}
					}
				}
			}
			
			&__header {
				
				margin-bottom: 30px;
				@media screen and (min-width: 992px) {
				margin-bottom: 15px;
				}
				
				.partner-title,
				.partner-logo {
					text-align: center;
				}
				
				.partner-title {
					margin-bottom: 15px;
					font-size: 16px;
					font-weight: 600;
					text-transform: uppercase;
					@media screen and (min-width: 992px) {
					margin-bottom: 20px;
					font-size: 20px;
					}
				}
				
				.partner-logo {
					height: 50px;
					@media screen and (min-width: 992px) {
						height: 80px;
						padding: 15px;
						background: #FFF;
					}
					img {
						height: 50px;
						@media screen and (min-width: 992px) {
						height: 80px;
						}
					}
				}
				
			}
			
			&__content {
				.partner-description {
					font-size: 11px;
					line-height: 18px;
					@media screen and (min-width: 992px) {
					font-size: 13px;
					}
					p {
						margin-bottom: 0;
					}
				}
			}
			
			&__footer {
				
				margin-top: 20px;
				font-weight: 600;
				
				.partner-www {
					a, span {}
					span {
						margin-right: 5px;
					}
				}
				
			}
		
		}
		
	}
	
}
