.team-page {
	
	&__team-players {
		
		&__team-top5 {
			
			position: relative;
			margin-top: 10px;
			padding: 0 20px;
			
			@media screen and (min-width: 992px) {
			margin-top: 20px;
			padding-left: 45px;
			}
			
			.team-top5-switcher {
				
				font-size: 12px;
				padding-bottom: 5px;
				text-transform: uppercase;
				border-bottom: 1px solid #D8D9D9;
				
				@media screen and (min-width: 992px) {
				padding: 0;
				font-size: 16px;
				margin-bottom: 20px;
				border-bottom: none;
				}
				
				.switcher-label {
					color: #17366E;
					font-weight: 600;
				}
				
				.switcher-buttons {
					
					text-align: right;
					
					span {
						
						margin-right: 5px;
						color: #555;
						
						&:last-child {
							margin-right: 0;
						}
						
						&.switcher-button {
							cursor: pointer;
						}
						
						&.selected {
							font-weight: 600;
							color: #222;
						}
						
					}
					
				}
				
			}
			
			.team-players-top5-header {
				
				@media screen and (min-width: 992px) {
				background: #E6E6E6;
				border-bottom: 2px solid #17366E;
				}
				
				.team-players-top5-header-content {
					@media screen and (min-width: 992px) {
					justify-content: space-between;
					}
				}
				
				.players-top5-header-stat {
					
					padding: 5px 5px;
					font-size: 10px;
					color: #FFF;
					text-transform: uppercase;
					cursor: pointer;
					background: #1D4288;
					border-bottom: 1px solid #FFF;
					
					@media screen and (min-width: 992px) {
					font-size: 11px;
					color: #222;
					background: none;
					border-bottom: none;
					}
					
					@media screen and (min-width: 1300px) {
					padding: 5px 8px;
					}
					
					&:last-child {
						border-bottom: none;
					}
					
					&:hover {
						color: #EE7C00;
					}
					
					&.selected {
						color: #FFF;
						background: #EE7C00;
						@media screen and (min-width: 992px) {
						background: #17366E;
						}
					}
					
				}
				
			}
			
			.team-players-top5-players {
				
				padding-left: 30px;
			
				@media screen and (min-width: 992px) {
				padding-left: 15px;
				}
				
				.team-players-top5-player {
					
					padding: 5px 15px;
					font-size: 10px;
					background: #E6E6E6;
					
					@media screen and (min-width: 992px) {
					font-size: 12px;
					}
					
					.team-players-top5-player-content {
						align-content: start;
						align-items: start;
						@media screen and (min-width: 992px) {
						align-content: center;
						align-items: center;
						}
					}
					
					&:nth-child(odd) {
						background: #FFF;
					}
					
					.player-position,
					.player-avatar,
					.player-score {
						text-align: center;
					}
					
					.player-position {
						padding: 0;
					}
					
					.player-avatar {
						
						padding: 0 5px;
						@media screen and (min-width: 992px) {
						padding: 0;
						}
						
						a {
							display: block;
							text-align: left;
							
							&:hover {
								opacity: 0.7;
							}
							
							img {
								max-height: 30px;
								@media screen and (min-width: 992px) {
								max-height: 40px;
								}
							}
							
						}
						
					}
					
					.player-name, .player-score {
						display: flex;
						height: 100%;
						align-items: center;
						align-content: center;
						line-height: 40px;
					}
					
					.player-name {
						padding-left: 0;
						font-weight: 600;
						text-transform: uppercase;
						line-height: 20px;
						@media screen and (min-width: 992px) {
						padding-left: 15px;
						}
					}
					
					.player-score {
						
						padding: 0;
						font-size: 14px;
						font-weight: 600;
						
						@media screen and (min-width: 992px) {
						padding: 0 15px;
						justify-content: right;
						font-size: 16px;
						}
						
					}
					
				}
				
			}
			
		}
		
	}
	
}
