.leagues-schedules {

	&__leagues-and-rounds-switcher {
		
		&__rounds-switcher {
			
			background: #1D4288;
			
			@media screen and (min-width: 992px) {
			height: 55px;
			border-bottom: 5px solid #17366E;
			}
		
			&__rounds-list {
				margin-top: -5px;
			}
			
		}
		
	}
	
}
