.service-footer-section {
	
	overflow: hidden;
	margin-top: 0;
	padding: 20px 15px;
	background: #17366E;
	border-top: 5px solid #1D4288;
	border-bottom: 5px solid #1D4288;
	
	@media screen and (min-width: 992px) {
	position: absolute;
	bottom: 70px;
	margin-bottom: 0;
	padding: 0;
	border-top: none;
	border-bottom: 10px solid #17366E;
	}
	
	.main-section-content {
		@media screen and (min-width: 992px) {
		background: #1D4288 url("/Assets/Images/Backgrounds/footer_bg.png") 0 top no-repeat;
		background-size: auto 100%;
		}
		@media screen and (min-width: 1200px) {
		width: calc(1170px + 10000px);
		padding: 20px 10000px 20px 15px;
		}
		@media screen and (min-width: 1320px) {
		width: calc(1300px + 10000px);
		}
	}
	
	&__logo,
	&__information {
		
		position: relative;
		height: 80px;
		
		@media screen and (min-width: 992px) {
		margin: 20px 0;
		}
		
	}
	
}
