.game-result-details {
	
	&__game-progress {
		
		&__quarters {
			
			&__quarter {
				
				&__footer {
					
					padding: 10px 15px;
					font-size: 12px;
					color: #FFF;
					background: #1D4288;
					
					@media screen and (min-width: 992px) {
					font-size: 14px;
					}
					
					&__content {
						justify-content: center;
					}
					
				}
				
			}
			
		}
		
	}
	
}
