.team-page {
	
	&__team-header {
		
		&__team-socials {
			
			margin-top: 10px;
			
			@media screen and (min-width: 992px) {
			position: absolute;
			right: 0;
			bottom: 0;
			margin-top: 0;
			}
			
			.team-socials-content {
				
				width: 100%;
				margin: 0;
				align-items: center;
				align-content: center;
				justify-content: space-between;
				
				@media screen and (min-width: 992px) {
				justify-content: right;
				}
				
			}
			
			.team-social {
				
				margin-right: 15px;
				padding: 0;
				
				&:last-child {
					margin-right: 0;
				}
				
				a {
					
					display: block;
					color: #FFF;
					
					&:hover {
						color: #EE7C00;
					}
					
					svg {
						width: 20px;
						height: 20px;
					}
					
				}
				
			}
			
		}
		
	}
}
