.team-page {
	
	&__team-players {
		
		position: relative;
		
		@media screen and (min-width: 992px) {
		padding: 10px 0 0;
		}
		
	}
	
}
