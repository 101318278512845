.leagues-schedules {
	
	&__leagues-and-rounds-switcher {
	
		&__leagues-switcher {
			
			background: #17366E;
			
			@media screen and (min-width: 992px) {
			height: 55px;
			border-bottom: 5px solid #17366E;
			}
			
			&__league {
			
				&--selected-league {
					
					color: #FFF;
					@media screen and (min-width: 992px) {
					background: #1D4288;
					}
					
				}
			
			}
			
			&__leagues-list {
				margin-top: -5px;
			}
		
		}
	
	}
	
}
