.game-details-admin {
	
	&__header {
		
		margin: 0 0 10px;
		padding-bottom: 10px;
		background: #FFF;
		border: 1px solid #D8D9D9;
		
		@media screen and (min-width: 992px) {
		margin-bottom: 20px;
		padding-bottom: 20px;
		border-width: 2px;
		}
		
	}
	
}
