.team-page {
	
	&__team-statistics {
		
		position: relative;
		padding: 0 5px;
		border: 2px solid #FFF;
		border-bottom: none;
		
		@media screen and (min-width: 992px) {
		width: calc(100% + 30px);
		margin: 0 -15px;
		padding: 10px 0;
		background: #17366E;
		border: none;
		border-bottom: 2px solid #1D4288;
		}
		
		.main-section-content {
			justify-content: space-between;
		}
		
		.information-label {
			
			color: #FFF;
			padding: 5px 15px;
			text-align: center;
			background: #17366E;
			border-bottom: 2px solid #FFF;
			
			@media screen and (min-width: 992px) {
			position: absolute;
			top: calc(50% - 10px);
			left: 50px;
			height: 20px;
			padding: 0;
			font-weight: 600;
			line-height: 20px;
			border-bottom: none;
			z-index: 100;
			}
			
			.mob {
				@media screen and (min-width: 992px) {
				display: none;
				}
			}
			
			.des {
				display: none;
				@media screen and (min-width: 992px) {
				display: block;
				}
			}
			
		}
		
		.team-information {
			
			padding: 5px;
			background: #17366E;
			border-bottom: 2px solid #FFF;
			border-right: 2px solid #FFF;
			
			@media screen and (min-width: 992px) {
			min-width: 13%;
			padding: 0 15px;
			background: none;
			border-bottom: none;
			}
			
			&:nth-child(6), &:last-child {
				@media screen and (max-width: 991px) {
				border-right: none;
				}
			}
			
			.team-information-content {
				
				height: 100%;
				justify-content: center;
				align-items: start;
				align-content: start;
				
				@media screen and (min-width: 992px) {
				align-items: center;
				align-content: center;
				}
				
			}
			
			.info-label, .info-value {
				padding: 0 5px;
			}
			
			.info-label, .info-value, a {
				color: #FFF;
				text-transform: uppercase;
				text-align: center;
			}
			
			.info-label {
				font-size: 9px;
				@media screen and (min-width: 992px) {
				font-size: 12px;
				}
			}
			
			.info-value {
				font-size: 14px;
				font-weight: 600;
				@media screen and (min-width: 992px) {
				font-size: 16px;
				}
			}
			
			a, span {
				display: block;
			}
			
			a {
				&:hover {
					color: #EE7C00;
				}
			}
			
			&.team-next-game {
				
				border-right: none;
				
				span {
					&.game-date, &.game-arena {
						font-size: 12px;
						line-height: 15px;
						color: #D8D9D9;
					}
				}
				
			}
			
		}
		
		
	}
	
}
