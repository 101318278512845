.leagues-schedules {
	
	&__leagues-and-rounds-switcher {
		
		&__rounds-switcher {
		
			&__round {
			
				&--selected-round {
					color: #FFF;
					@media screen and (min-width: 992px) {
					background: #17366E;
					}
				}
				
			}
			
		}
		
	}
	
}
