.home-page-leagues-component {
	
	padding: 0 0 20px;
	text-transform: uppercase;
	border-top: 5px solid #E5E5E5;
	border-bottom: 5px solid #E5E5E5;
	
	@media screen and (min-width: 992px) {
	padding: 20px 15px;
	}
	
	.home-page-leagues-header {
		
		margin-bottom: 10px;
		padding: 10px 0;
		font-size: 16px;
		font-weight: 600;
		text-align: center;
		border-top: 2px solid #D8D9D9;
		border-bottom: 2px solid #D8D9D9;
		
		@media screen and (min-width: 992px) {
		margin-bottom: 20px;
		padding: 0 0 20px;
		font-size: 18px;
		border-top: none;
		border-bottom-width: 5px;
		}
		
	}
	
	.home-page-leagues-statistics {
		
		margin-bottom: 20px;
		@media screen and (min-width: 992px) {
		//margin-bottom: 0;
		padding-left: 30px;
		}
		
		.leagues-statistics-table {
			
			display: flex;
			align-self: flex-start;
			flex-direction: column;
			justify-content: center;
			padding: 0 20px;
			
			@media screen and (min-width: 992px) {
			padding: 0 15px
			}
			
			.statistics-table-header {

				@media screen and (min-width: 992px) {
				margin-bottom: 5px;
				padding-top: 5px;
				background: #17366E;
				}
				
				.statistics-table-header-content {
					justify-content: space-between;
				}
				
				.statistics-table-data-type {
					
					width: 100%;
					padding: 5px 15px;
					font-size: 10px;
					color: #FFF;
					border-bottom: 1px solid #D8D9D9;
					
					line-height: 20px;
					background: #1D4289;
					cursor: pointer;
					
					@media screen and (min-width: 992px) {
					width: auto;
					padding: 5px 10px;
					text-align: center;
					border-bottom: none;
					}
					
					&:last-child {
						border-bottom: none;
					}
					
					&:hover {
						color: #EE7C00;
					}
					
					&.selected {
						
						background: #EE7C00;
						
						@media screen and (min-width: 992px) {
						color: #17366E;
						background: #FFF;
						//border-top-left-radius: 15px;
						}
						
					}
					
					span {
						display: block;
						line-height: 20px;
					}
					
				}
				
			}
			
			.statistics-table-content {
				
				padding-left: 20px;
				
				@media screen and (min-width: 992px) {
				padding-left: 15px;
				}
				
				.statistics-table-player {
					
					margin-bottom: 5px;
					padding-top: 5px;
					font-size: 10px;
					font-weight: 600;
					background: #D8D9D9;
					
					@media screen and (min-width: 992px) {
					font-size: 11px;
					}
					
					&:last-child {
						margin-bottom: 0;
					}
					
					.statistics-table-position,
					.statistics-table-player-data,
					.statistics-table-data {
						
						padding: 0 10px 5px;
						text-align: center;
						
						@media screen and (min-width: 992px) {
						padding: 5px;
						}
						
					}
					
					.statistics-table-position {}
					
					.statistics-table-player-data {
						
						.player-avatar,
						.player-information  {}
						
						.player-avatar {
							
							padding: 0 5px;
							
							@media screen and (min-width: 992px) {
							padding: 0;
							}
							
							a {
								
								display: block;
								
								&:hover {
									opacity: 0.7;
								}
								
								img {
									
									max-width: 30px;
									max-height: 30px;
									
									@media screen and (min-width: 992px) {
									max-width: 40px;
									max-height: 40px;
									}
									
								}
								
							}
							
						}
						
						.player-information  {
							
							padding-left: 0;
							line-height: 18px;
							
							.player-name-data,
							.player-team-data {
								text-align: left;
								align-items: center;
								justify-content: center;
								a {
									display: block;
								}
							}
							
							.player-name-data {
								margin-bottom: 0;
								padding-right: 0;
								font-size: 9px;
								@media screen and (min-width: 992px) {
								font-size: 11px;
								}
							}
							
							.player-team-data {
								
								font-size: 8px;
								line-height: 12px;
								
								@media screen and (min-width: 992px) {
								font-size: 9px;
								}
								
								.player-team-name {}
								
							}
							
						}
						
					}
					
					.statistics-table-data {
						
						.statistics-data-value {
							
							padding: 0;
							font-size: 10px;
							line-height: 18px;
							
							@media screen and (min-width: 992px) {
							font-size: 15px;
							line-height: 15px;
							}
							
						}
						
						.statistics-data-label {
							
							padding: 0 10px 0 0;
							font-size: 8px;
							line-height: 15px;
							
							@media screen and (min-width: 992px) {
							padding: 0 15px 0 0;
							font-size: 11px;
							}
							
						}
						
					}
					
				}
				
			}
			
			}
			
		
	}
	
}

.home-page-leagues-awards {
	display: none;
	@media screen and (min-width: 992px) {
	display: block;
	padding-left: 30px;
	}
}
