

.text-button {
	
	display: inline-flex;
	align-items: center;
	align-content: center;
	cursor: pointer;
	
	&:hover {
		color: #EE7C00;
	}
	
	&__text {
		font-weight: 600;
	}
	
	&__icon {
		width: 16px;
		height: 16px;
		margin-top: -2px;
		margin-left: 5px;
	}
	
}
