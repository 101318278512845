.captain-team-players-list {
	
	&__player {
		
		margin-bottom: 5px;
		text-transform: none;
	
		@media screen and (min-width: 992px) {
		margin-bottom: 0;
		}
		
		.form-group {
			
			position: relative;
			margin-bottom: 10px;
			padding: 0 5px 10px 10px;
			text-align: center;
			border-bottom: 1px solid #D8D9D9;
			
			@media screen and (min-width: 992px) {
			margin-bottom: 0;
			//padding: 0 30px 0 5px;
			border-bottom: none;
			}
			
			&:last-child {
				margin-bottom: 0;
				padding-bottom: 0;
				border-bottom: none
			}
			
			.form-field {}
			
			.form-field-content {
				margin: 0;
				justify-content: center;
			}
			
			.form-label {
				
				margin-bottom: 2px;
				padding-left: 15px;
				font-weight: 500;
				line-height: 15px;
				
				@media screen and (min-width: 992px) {
				display: none;
				padding-left: 0;
				}
				
			}
			
			.form-control, .form-field-text {
				
				font-size: 10px;
				
				@media screen and (min-width: 992px) {
				font-size: 11px;
				}
				
			}
			
			.form-control {
				
				height: 20px;
				padding: 0 5px;
				
				@media screen and (min-width: 992px) {
				height: 30px;
				}
				
				&::placeholder {
					font-size: 10px;
					@media screen and (min-width: 992px) {
					font-size: 11px;
					}
				}
				
			}
			
			.form-field-text {
				
				padding: 0;
				text-align: left;
				line-height: 15px;
				
				@media screen and (min-width: 992px) {
				text-align: center;
				line-height: 24px;
				}
				
			}
			
			.form-field-actions {
				
				margin-top: 5px;
				
				
				@media screen and (min-width: 992px) {
				height: 20px;
				line-height: 14px;
				}
				
				&:not(.active) {
					
					position: absolute;
					top: 0;
					right: 20px;
					margin-top: 0;
					padding: 0;
					text-align: right;
					line-height: 12px;
					z-index: 100;
					
					@media screen and (min-width: 992px) {
					top: 5px;
					right: 20px;
					}
					
				}
				
				.form-field-actions-content {
					justify-content: right;
					justify-content: center;
				}
				
				.form-field-action {
					
					padding: 0 2px;
					font-size: 9px;
					cursor: pointer;
					
					@media screen and (min-width: 992px) {
					padding: 0 3px;
					}
					
					svg {
						margin-left: 2px;
					}
					
					&:hover {
						color: #EE7C00;
					}
					
				}
				
				svg {
					
					vertical-align: top;
					width: 12px;
					height: 12px;
					
					@media screen and (min-width: 992px) {
					width: 14px;
					height: 14px;
					}
					
				}
				
			}
			
		}
		
		.player-name,
		.player-social-id,
		.player-email {
			
			margin-bottom: 5px;
			padding-bottom: 5px;
			border-bottom: 1px solid #D8D9D9;
			
			@media screen and (min-width: 992px) {
			margin-bottom: 0;
			padding-bottom: 0;
			border-bottom: none;
			}
			
		}
		
		.player-first-name, .player-last-name {
			.form-control, .form-field-text {
				font-weight: 600;
				text-align: left;
			}
		}
		
		.player-actions {
			
			padding-right: 5px;
			text-align: right;
			
			@media screen and (min-width: 992px) {}
			
			.player-action {
				
				margin-right: 10px;
				cursor: pointer;
				
				&.save-action {
					color: #1C7430;
				}
				
				&.abort-action {
					color: #E2000F;
				}
				
				&.active {
					color: #EE7C00;
				}
				
				&:last-child {
					margin-right: 0;
				}
				
				&:hover {
					color: #EE7C00;
				}
				
				svg {
					
					width: 14px;
					height: 14px;
					vertical-align: top;
					
					@media screen and (min-width: 992px) {
						width: 18px;
						height: 18px;
					}
					
				}
				
			}
			
		}
		
		.player-email-content, .player-number-content {
			justify-content: center;
		}
		
	}
	
}
