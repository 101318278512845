.game-information-summary {
	
	position: relative;
	padding: 0 20px;
	
	@media screen and (min-width: 992px) {
	margin-top: 50px;
	padding: 0 15px;
	}
	
	&__teams {
		
		position: relative;
		
		&__team {
			
			position: relative;
			text-align: center;
			
			.team-name,
			.team-logo {}
			
			.team-name {
				
				display: flex;
				justify-content: center;
				align-items: center;
				align-content: center;
				min-height: 40px;
				padding: 5px 10px;
				line-height: 15px;
				background: #17366E;
				
				@media screen and (min-width: 992px) {
				padding: 10px 15px;
				line-height: 20px;
				}
				
				a {
					
					font-size: 13px;
					color: #FFF;
					
					@media screen and (min-width: 992px) {
					font-size: 18px;
					font-weight: 600;
					}
					
					&:hover {
						color: #EE7C00;
					}
					
				}
				
			}
			
			.team-logo {
				
				padding: 15px;
				background: #FFF;
				border: 1px solid #D8D9D9;
				
				a {
					
					display: block;
					
					img {
						min-height: 70px;
						max-height: 70px;
						@media screen and (min-width: 992px) {
						min-height: 150px;
						max-height: 150px;
						}
					}
					
					&:hover {
						opacity: 0.7;
					}
					
				}
				
			}
			
			&.home-team {
				.team-name {
					border-left: 10px solid #1D4288;
					@media screen and (min-width: 992px) {
					border-left-width: 15px;
					}
				}
			}
			
			&.away-team {
				.team-name {
					border-right: 10px solid #1D4288;
					@media screen and (min-width: 992px) {
					border-right-width: 15px;
					}
				}
			}
			
		}
		
	}
	
	.game-information-summary-table {
		
		position: relative;
		color: #FFF;
		font-size: 10px;
		text-align: center;
		line-height: 15px;
		
		@media screen and (min-width: 992px) {
		font-size: 16px;
		//font-weight: 600;
		line-height: 20px;
		}
		
		.summary-table-header-row {
			
			&:first-child {
				margin-top: -40px;
				@media screen and (min-width: 992px) {
				margin-top: 0;
				}
			}
			
			.summary-table-header {
				
				padding: 10px 10px;
				background: #17366E;
				
				@media screen and (min-width: 992px) {
				padding: 10px 15px;
				}
				
			}
			
		}
		
		.game-information-summary-table-row {
			
			background: #1D4288;
			
			&:nth-child(odd) {
				background: #17366E;
			}
			
			.summary-table-label,
			.summary-table-value {
				
				display: flex;
				justify-content: center;
				align-content: center;
				align-items: center;
				padding: 5px 10px;
				
				@media screen and (min-width: 992px) {
				padding: 5px 15px;
				}
				
			}
			
			.summary-table-label {
				
				color: #17366E;
				background: #EBEBEB;
				
				span {
					margin-top: 2px;
				}
				
				strong {
					margin-top: 1px;
					color: #EE7C00;
				}
				
			}
			
			.summary-table-value {
				
				padding: 5px 15px;
				@media screen and (min-width: 992px) {
				padding: 10px 15px;
				}
				
				&.empty-content {
					background: #F8F8F8;
				}
				
				.table-value-label {
					
					margin-right: 15px;
					@media screen and (min-width: 992px) {
					margin-right: 30px;
					}
					
					&.full-width {
						display: block;
						margin-right: 100%;
					}
					
				}
				
				.table-value-value {
					
					margin-right: 15px;
					
					@media screen and (min-width: 992px) {
					margin-right: 30px;
					}
					
					&:last-child {
						margin-right: 0;
						font-weight: 600;
					}
					
				}
				
			}
			
			&:nth-child(odd) {
				.summary-table-label {
					background: #FFF;
				}
			}
			
		}
		
	}
	
}
