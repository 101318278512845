.game-tables-team-table-team {
	position: static;
	@media screen and (min-width: 992px) {
	position: relative;
	}
}

.game-tables-team-table-team-player {
	
	position: static;
	height: 25px;
	@media screen and (min-width: 992px) {
	position: relative;
	height: 40px;
	}
	
	&:nth-child(odd) {
		background: #D8D9D9;
		.table-row-cell {
			background: #D8D9D9;
		}
	}
	
	&:last-child {}
	
	.table-row-cell {
		
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		align-content: center;
		align-items: center;
		justify-content: center;
		height: 100%;
		font-size: 10px;
		line-height: 15px;
		background: #FFF;
		
		@media screen and (min-width: 992px) {
		font-size: 12px;
		line-height: 25px;
		}
		
		a {
			display: block;
		}
		
		&.team-player-number {
			
			font-size: 11px;
			font-weight: 600;
			
			@media screen and (min-width: 992px) {
			font-size: 18px;
			font-weight: 500;
			}
			
		}
		
		&.team-player-name {
			
			font-size: 10px;
			font-weight: 400;
			text-transform: none;
			
			@media screen and (min-width: 992px) {
			font-size: 14px;
			}
			
			a {
				color: #000004;
				&:hover {
					color: #EE7C00;
				}
			}
			
			&.first-squad {
				font-weight: 700;
			}
			
		}
		
		&.team-player-minutes {}
		
		&.team-player-2pt-throws, &.team-player-2pt-throws-eff,
		&.team-player-3pt-throws, &.team-player-3pt-throws-eff,
		&.team-player-1pt-throws, &.team-player-1pt-throws-eff,
		&.team-player-game-throws, &.team-player-game-throws-eff {}
		
		&.team-player-1pt-throws-eff {}
		
		&.team-player-2pt-throws, &.team-player-3pt-throws,
		&.team-player-1pt-throws, &.team-player-game-throws {}
		
		&.team-player-2pt-throws-eff,
		&.team-player-3pt-throws-eff,
		&.team-player-1pt-throws-eff,
		&.team-player-game-throws-eff {}
		
		&.team-player-minutes, &.team-player-1pt-throws-eff {}
		
		&.team-player-fouls {
			
			span.statistic-value {
				min-width: 100%;
				min-height: 23px;
				line-height: 23px;
				@media screen and (min-width: 992px) {
				min-height: 40px;
				line-height: 40px;
				}
			}
			
			&.equal-or-over-5-fouls {
				font-weight: 600;
			}
			
			&.not-sport-foul, &.technical-foul, &.disqualification-foul {
				span.statistic-value {
					color: #FFF;
					font-weight: 600;
				}
			}
			
			&.not-sport-foul {
				span.statistic-value {
					background: #993B99;
				}
			}
			
			&.technical-foul {
				span.statistic-value {
					background: #EE7C00;
				}
			}
			
			&.not-sport-foul.technical-foul {
				span.statistic-value {
					background: linear-gradient(90deg, #993B99 50%, #EE7C00 50%);
				}
			}
			
			&.disqualification-foul {
				background: #E2000F;
			}
			
		}
		
		.statistic-label, .statistic-value {
			display: block;
		}
		
		.statistic-value {}
		
	}
	
}
