.captain-team-players-list {
	
	
	position: relative;
	font-size: 10px;
	
	@media screen and (min-width: 992px) {
	font-size: 12px;
	}
	
	
	&__row {
		
		padding: 5px 15px;
		background: #FFF;
		
		&.not-activated {
			border: 1px solid #E2000F;
		}
		
		&.regulations-not-accepted {
			border: 1px solid #EE7C00;
		}
		
		&:nth-child(even) {
			background: #E6E6E6;
		}
	
	}
	
	&__header {
		
		display: none;
		padding: 5px 15px;
		color: #FFF;
		background: #1D4288;
		
		@media screen and (min-width: 992px) {
		display: block;
		}
		
	}
	
	.player-inactive-status-information {
		
		padding-top: 5px;
		font-size: 11px;
		font-weight: 600;
		text-align: center;
		border-top: 1px solid #E2000F;
		
		@media screen and (min-width: 992px) {
			padding-top: 0;
			border-top: none;
		}
		
	}
	
	&__row {
		
		&.not-activated {
			.player-inactive-status-information {
				color: #E2000F;
			}
		}
		
		&.regulations-not-accepted {
			.player-inactive-status-information {
				color: #EE7C00;
			}
		}
		
		.player-social-id, .player-email {
			text-align: center;
		}
		
	}
	
}
