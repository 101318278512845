.team-page {
	
	&__team-players {
		
		&__team-photo {
			
			padding: 10px;
			
			@media screen and (min-width: 992px) {
			padding: 15px;
			}
			
			picture {
				
				height: 100%;
				margin: 0 -30px;
				justify-content: center;
				align-content: center;
				align-items: center;
				
				img {
					max-height: 200px;
					@media screen and (min-width: 992px) {
					max-height: 320px;
					}
				}
				
			}
			
		}
		
	}
	
}
