.player-profile {
	
	&__captain-zone {
		
		&__team {
			
			&__players {
			
				&__add-player {
					
					padding: 10px 30px 0;
					margin-top: 15px;
					background: #FFF;
					border: 1px solid #D8D9D9;
					@media screen and (min-width: 992px) {
					margin-top: 25px;
					}
					
					&__header {
						
						display: flex;
						margin-bottom: 10px;
						padding: 0;
						font-weight: 600;
						text-align: right;
						justify-content: right;
						
						.header-label,
						.header-action {
							display: inline-flex;
							height: 20px;
							line-height: 20px;
						}
						
						.header-label {
							align-self: start;
						}
						
						.header-action {
							
							align-items: start;
							align-content: start;
							margin-left: 5px;
							cursor: pointer;
							
							svg {
								margin-top: 1px;
								width: 16px;
								height: 16px;
								vertical-align: top;
							}
							
						}
						
					}
					
					.form-field {
						
						@media screen and (min-width: 992px) {
						padding-right: 30px;
						}
						
						&.default-padding {
							padding: 0 15px;
						}
						
						&.submit-form-field {
							text-align: center;
							.form-field-content {
								justify-content: center;
							}
						}
						
					}
					
				}
				
			}
			
		}
	}
	
}
