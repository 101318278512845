.game-result-team-points {
	
	font-weight: 500;
	text-align: center;
	@media screen and (min-width: 992px) {}
	
	.game-result-team-points-content {
		
		height: 100%;
		margin: 0 -10px;
		background: #F1F1F1;
		
		@media screen and (min-width: 992px) {}
		
	}
	
	&.home-team-points {
		@media screen and (min-width: 992px) {
		.game-result-team-points-content {
			border-bottom-right-radius: 10px;
		}
		}
	}
	
	&.away-team-points {
		@media screen and (min-width: 992px) {
		.game-result-team-points-content {
			border-bottom-left-radius: 10px;
		}
		}
	}
	
	.team-name {
		
		position: relative;
		overflow: hidden;
		height: 30px;
		padding: 5px;
		background: #17366E;
		@media screen and (min-width: 992px) {
		height: 40px;
		padding: 5px 0;
		}
		
		a {
			display: block;
			position: relative;
			color: #FFF;
			font-size: 12px;
			line-height: 20px;
			z-index: 2;
			@media screen and (min-width: 992px) {
			font-size: 20px;
			line-height: 30px;
			}
			&:hover {
				color: #EE7C00;
			}
			span {
				display: block;
				position: relative;
				white-space: nowrap;
				overflow: hidden;
				overflow-x: scroll;
				width: 100%;
				height: 20px;
				z-index: 2;
				@media screen and (min-width: 992px) {
				overflow: hidden;
				height: auto;
				}
			}
		}
		
		span.bg {
			display: none;
			position: absolute;
			top: -5px;
			width: calc(80%);
			height: 30px;
			z-index: 1;
			background: #1D4288;
			@media screen and (min-width: 992px) {
			display: block;
			height: 40px;
			}
		}
		
	}
	
	&.home-team-points {
		span.bg {
			right: 20%;
			border-bottom-right-radius: 40px;
		}
	}
	
	&.away-team-points {
		span.bg {
			left: 20%;
			border-bottom-left-radius: 40px;
		}
	}
	
	&.game-winner {
		font-weight: 600;
		@media screen and (min-width: 992px) {
		font-weight: 700;
		}
	}
	
	.team-points {
		
		display: flex;
		height: 50px;
		align-items: center;
		align-content: center;
		justify-content: center;
		font-size: 40px;
		background: url("/Assets/Images/Pages/Games/Game/Result/game_result_header_team_result_bg.jpg") center center no-repeat;
		background-size: auto 100%;
		
		@media screen and (min-width: 992px) {
		flex: 0 0 100%;
		height: calc(100% - 40px);
		padding: 10px 0 55px;
		font-size: 70px;
		background-position-y: calc(100% - 50px);
		}
		
	}
	
}
