

.leagues-tables-leagues-list-component {
	
	margin-bottom: 5px;
	padding: 0 0 0 20px;
	
	@media screen and (min-width: 992px) {
	margin-bottom: 0;
	padding: 0;
	}
	
	.leagues-list-league {
		
		margin-bottom: 5px;
		margin-right: 0;
		padding: 8px 10px;
		text-align: center;
		background: #17366E;
		
		@media screen and (min-width: 992px) {
		padding: 10px;
		border-left: 10px solid #EE7C00;
		}
		
		&:last-child {}
		
		.leagues-list-name {
			
			padding: 0;
			font-size: 11px;
			color: #FFF;
			line-height: 18px;
			cursor: pointer;
			
			@media screen and (min-width: 992px) {
			font-size: 15px;
			line-height: 24px;
			}
			
			span {
				
				overflow-y: scroll;
				display: block;
				width: 100%;
				height: 18px;
				
				@media screen and (min-width: 992px) {
				overflow: hidden;
				height: 24px;
				}
				
			}
			
			&:hover, &.selected {
				color: #EE7C00;
			}
			
			&.selected {
				font-weight: 600;
			}
			
		}
		
	}
}

.leagues-tables-table {
	
	margin-bottom: 20px;
	padding: 0 20px;
	align-content: center;
	align-items: center;
	align-self: center;
	
	@media screen and (min-width: 992px) {
	margin-bottom: 0;
	padding: 0 15px;
	}
	
	.leagues-tables-group {
		
		margin-bottom: 20px;
		padding: 0;
		
		.leagues-tables-group-header {
			
			margin-bottom: 5px;
			
			.group-header {
				color: #222;
				font-weight: 600;
			}
			
		}
		
	}
	
	.tables-table-team {
		
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: stretch; // <-- stretch vertical (default value, can be omitted here)
		align-content: center;
		
		margin-bottom: 5px;
		padding-top: 5px;
		padding-right: 10px;
		background: #1D4288;
		
		@media screen and (min-width: 992px) {
		margin-left: 0;
		padding-right: 0;
		background: #17366E;
		}
		
		.table-team-position,
		.table-team-logo,
		.table-team-name,
		.table-team-games,
		.table-team-results,
		.table-team-points {
			
			display: flex;
			padding: 0 0 5px;
			align-items: center;
			align-content: center;
			justify-content: center;
			font-size: 9px;
			color: #FFF;
			
			@media screen and (min-width: 992px) {
			font-size: 11px;
			}
			
		}
		
		.table-team-name,
		.table-team-games,
		.table-team-results,
		.table-team-points {
			@media screen and (min-width: 992px) {
			background: #1D4289;
			}
		}
		
		.table-team-position {
			padding: 0 10px 5px 5px;
			text-align: center;
			@media screen and (min-width: 992px) {
			padding: 0 0 5px;
			}
		}
		
		.table-team-logo {
			
			overflow: hidden;
			padding: 0 0 5px;
			text-align: center;
			
			@media screen and (min-width: 992px) {
			padding: 0 10px 5px 0;
			}
			
			a {
				display: block;
				&:hover {
					opacity: 0.7;
				}
				img {
					max-height: 25px;
				}
			}
			
		}
		
		.table-team-name {
			
			padding-left: 10px;
			font-size: 10px;
			font-weight: 500;
			line-height: 16px;
			justify-content: flex-start;
			
			@media screen and (min-width: 992px) {
			padding-left: 20px;
			border-bottom-left-radius: 25px;
			}
			
			a {
				color: #FFF;
				&:hover {
					color: #EE7C00;
				}
			}
			
		}
		
		.table-team-games {
			padding: 0 5px 5px;
			text-align: center;
			@media screen and (min-width: 992px) {
			padding: 0 0 5px;
			}
		}
		
		.table-team-results {
			text-align: center;
		}
		
		.table-team-points {
			padding: 0 0 5px;
			font-weight: 600;
			text-align: right;
			@media screen and (min-width: 992px) {
			text-align: center;
			}
		}
		
	}
	
	.leagues-tables-full-table-link {
		
		margin-top: 10px;
		@media screen and (min-width: 992px) {
		margin-top: 20px;
		}
		
		.leagues-tables-full-table {
			
			text-align: right;
			
			a {
				font-weight: 600;
			}
			
		}
		
	}
	
}
