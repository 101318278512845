.articles-list-page {
	
	&__articles-list {
		
		&__article {
			
			margin-bottom: 15px;
			
			@media screen and (min-width: 992px) {
			margin-bottom: 30px;
			padding: 0 30px;
			}
			
			&__image {
				
				margin-bottom: 10px;
				text-align: center;
				@media screen and (min-width: 992px) {
				margin-bottom: 15px;
				}
				
				a {
					display: block;
					&:hover {
						opacity: 0.7;
					}
				}
				
				img {
					max-height: 100px;
					@media screen and (min-width: 992px) {
					max-height: 200px;
					}
				}
				
			}
			
			&__content {
			
				.article-title {
					
					position: relative;
					padding-right: 30px;
					
					a {
						
						display: block;
						max-height: 40px;
						padding: 5px 30px 5px 15px;
						color: #FFF;
						text-transform: uppercase;
						background: #17366E;
						line-height: 25px;
						border-bottom: 5px solid #E5E5E5;
						
						&::before, &::after {
							position: absolute;
							content: "";
							top: 0;
							width: 15px;
							height: 35px;
						}
						
						&::before {
							right: 15px;
							border-top: 40px solid #E5E5E5;
							border-right: 10px solid transparent;
						}
						
						&::after {
							right: 30px;
							border-top: 40px solid transparent;
							border-right: 10px solid #E5E5E5;
						}
						
					}
					
				}
				
				.article-short-content {
					margin-top: 10px;
					font-size: 11px;
					line-height: 15px;
				}
			
			}
			
		}
		
	}
	
}
