.home-page {
	
	&__articles {
	
		margin: 0 0 10px;
		padding: 10px 0 0;
		border-top: 2px solid #E5E5E5;
	
		&:first-child {
			margin-top: 10px;
			padding-top: 0;
			border-top: none;
		}
		
	}
	
}


