.game-tables-team-header {
	
	margin-bottom: 10px;
	border-top: 1px solid #D8D9D9;
	
	@media screen and (min-width: 992px) {
	margin-bottom: 0;
	border-top: none;
	}
	
}
