.article-detail {
	
	&__content {
		
		&__header {
			
			&__image-and-title {
				
				&__title {
					
					padding-right: 30px;
					
					&__content {
						margin: 0;
					}
					
					.article-title-text {
						
						display: block;
						position: relative;
						width: 100%;
						height: 45px;
						padding: 10px 15px;
						font-weight: 600;
						color: #FFF;
						text-transform: uppercase;
						line-height: 20px;
						background: #17366E;
						border-bottom: 5px solid #E5E5E5;
						
						@media screen and (min-width: 992px) {
						height: 60px;
						padding: 15px;
						font-size: 20px;
						border-bottom-width: 10px;
						}
						
						&::before, &::after {
							
							position: absolute;
							content: "";
							top: 0;
							width: 15px;
							height: 45px;
							
							@media screen and (min-width: 992px) {
							height: 60px;
							}
							
						}
						
						&::before {
							right: -15px;
							border-top: 45px solid #E5E5E5;
							border-right: 15px solid transparent;
							@media screen and (min-width: 992px) {
							border-top-width: 60px;
							}
						}
						
						&::after {
							right: 0px;
							border-top: 45px solid transparent;
							border-right: 15px solid #E5E5E5;
							@media screen and (min-width: 992px) {
							border-top-width: 60px;
							}
						}
						
					}
					
				}
				
			}
			
		}
		
	}
	
}
