.default-page-header {
	
	padding: 25px;
	text-transform: uppercase;
	border-bottom: 2px solid #17366E;
	
	@media screen and (min-width: 992px) {
	margin-top: 0;
	padding: 15px 0;
	}
	
	@media screen and (min-width: 1366px) {
	padding: 20px 0;
	}
	
	.container {
	@media screen and (min-width: 1366px) {
	max-width: 1300px;
	}
	}
	
	.default-page-header-heading {
		margin: 0;
		font-size: 20px;
		font-weight: 700;
		text-align: center;
		@media screen and (min-width: 992px) {
			font-size: 24px;
			text-align: left;
		}
	}
	
	.page-component-header-search {
		margin-top: 10px;
		@media screen and (min-width: 992px) {
		margin-top: 0;
		}
		.form-control {
			height: 30px;
			font-size: 12px;
			text-transform: uppercase;
		}
	}
	
}

